<template>
  <div>
    <b-row class="my-2">
      <!-- left part  -->
      <b-col cols="12" md="6">
        <div
          class="text-white bg-blue p-4 m-3 mycard position-relative leftPart"
        >
          <div v-if="!isPlanDetailClicked" class="p-3">
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <h1>
                {{ dataCapacityName }}
              </h1>
              <h2>
                {{ currentPlan }}
              </h2>
            </div>
            <p class="my-3">
              Unlimited Canada-wide Calling & <br />
              International Texting
            </p>
            <div class="position-absolute" style="bottom: 30px; right: 40px">
              <div
                class="d-flex flex-row justify-content-end align-items-center"
              >
                <p class="m-0">Details</p>
                <i
                  @click="isPlanDetailClicked = true"
                  class="fas fa-chevron-circle-right ml-2 text-white"
                ></i>
              </div>
            </div>
          </div>
          <div v-else>
            <ul>
              <li>UNLIMITED Canada-wide calling</li>
              <li v-if="isTelus">Up to 1,000 minutes of International Calling 
                <span @click="onClickListOfCountries" class="list-of-countries"> {{ `(List of countries)` }}</span>
              </li>
              <li>UNLIMITED International text and picture messaging</li>
              <li>
                Supported on the
                {{ getTheCarrier(carrier) }} Network
              </li>
              <li>Call Display</li>
              <li>Voicemail - Upto 35 minutes of messages</li>
              <li v-show="dataOverage !== '$0.00/1MB'">
                Data Overage Rate: {{ dataOverage }}
              </li>
            </ul>
            <div class="position-absolute" style="bottom: 20px; right: 50px">
              <div
                class="d-flex flex-row justify-content-end align-items-center"
                style="font-size: 14px; font-weight: 300"
                @click="isPlanDetailClicked = false"
              >
                <i class="fas fa-chevron-circle-up ml-2 text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <!-- right part -->
      <b-col cols="12" md="6">
        <div class="bg-white m-3 mycard rightPart">
          <b-row>
            <b-col lg="7">
              <div class="p-4">
                <p>Managing your data usage can be difficult.</p>
                <p>
                  We've made a handy guide for you to track your monthly data
                  usage on both IOS and Android devices.
                </p>
                <p
                  class="new-tab-link"
                  @click="openNewTabOnDataControl"
                  style="color: #39c2cf"
                >
                  Click Here to Learn More
                </p>
              </div>
            </b-col>
            <b-col lg="5">
              <img
                src="../../../assets/data.jpeg"
                class="mycard dataOverageImage"
                width="100%"
                height="230px"
                alt=""
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <el-dialog
      :visible.sync="showPopup"
      width="70%"
    >
      <div class="img-background">
        <div class="white-cursive-text">Eligible Countries</div>
        <div style="color: white; width: fit-content; margin: 12px auto; padding: 0px 12px;">International calling services available to a total of 30 countries, including:</div>
        <div class="column-container">
            <div class="responsive-columns">
              <li>Argentina</li> 
              <li>Australia</li> 
              <li>Bangladesh</li> 
              <li>Brazil</li> 
              <li>China</li> 
              <li>Colombia</li> 
              <li>Costa Rica</li> 
              <li>France</li> 
              <li>Germany</li> 
              <li>Hong Kong</li> 
              <li>India</li> 
              <li>Ireland</li> 
              <li>Italy</li> 
              <li>Japan</li> 
              <li>Korea</li> 
              <li>Malaysia</li> 
              <li>Mexico</li> 
              <li>Netherlands</li> 
              <li>Nigeria</li> 
              <li>Pakistan</li> 
              <li>Peru</li> 
              <li>Philippines</li> 
              <li>Poland</li> 
              <li>Singapore</li> 
              <li>Spain</li> 
              <li>Sri Lanka</li> 
              <li>Taiwan</li> 
              <li>United Kingdom</li> 
              <li>United States</li> 
              <li>Vietnam</li> 
            </div>
          </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPlanDetailClicked: false,
      dataCapacityName: "",
      currentPlan: "",
      carrier: "",
      dataOverage: "",
      showPopup: false,
    };
  },
  computed: {
    isTelus() {
      return this.carrier.toLowerCase() === "telus";
    }
  },
  methods: {
    findCurrentPlanDataCap(currentPlanPfId) {
    let urlQuery = '?PlanId=' + currentPlanPfId;

    this.$axios
      .post('/Activation/PostpaidPlanById' + urlQuery)
      .then(response => {
        let postPlans = response.data;
        let plan = postPlans[0];
        this.dataCapacityName = plan.dataCap;
      })
      .catch(error => {
        console.log(error);
      });
    },
    openNewTabOnDataControl() {
      let win = window.open(
        "https://files.constantcontact.com/aed72bc3701/51fd9aef-f4f1-4b15-ac8e-67b8ca4efb95.pdf",
        "_blank"
      );
      win.focus();
    },
    getTheCarrier(carrier) {
      var carrierCode = carrier.charAt(0);
      if (carrierCode === "T") {
        return "Telus";
      } else if (carrierCode === "R") {
        return "Rogers";
      } else {
        return "Bell";
      }
    },
    getClientInfo() {
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.currentPlan = data.CurrentPlan;
          this.dataOverage = data.DataOverage;
          this.carrier = data.Carrier;
          this.findCurrentPlanDataCap(data.CurrentPlan_pfId);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onClickListOfCountries() {
      this.showPopup = !this.showPopup;
    }
  },
  mounted() {
    this.getClientInfo();
  },
};
</script>

<style lang="scss" scoped>
.leftPart,
.rightPart,
.dataOverageImage {
  height: 240px;
}

.new-tab-link {
  color: #39c2cf;
}

.new-tab-link:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .leftPart {
    height: 320px;
  }
  .dataOverageImage {
    display: none;
  }
}
</style>
