<template>
  <div class="bg-main p-3 d-flex align-items-center justify-content-center mycard h-100 position-relative">
    <div @click="handleBack">
      <BackArrow />
    </div>
    <div @click="handleBack">
      <MobileCloseButton />
    </div>
    <div v-if="accountSuspended">
      <div class="text-center d-flex flex-column p-4">
        <h3 class="text-blue m-2">
          Your plan is currently on Vacation Plan <br />
          from {{ vacationStartDate }}, <br />
          your service will resume on {{ vacationEndDate }}.
        </h3>
        <p>
          Please contact our customer support at +1-855-886-0505 <br />
          or services@gophonebox.com to resume your service.
        </p>
        <div class="m-2">
          <button class="mybtn-blue" @click="$router.push('/home')">
            Home
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="isSuspensionRequested">
      <div class="text-center d-flex flex-column p-4">
        <h3 class="text-blue m-2">
          Your Line Has Been Requested to Be <br />
          Suspended on {{ suspensionDate }}
        </h3>
        <p>
          Please contact our customer support at +1-855-886-0505 <br />
          or services@gophonebox.com to resume your service early.
        </p>
        <div class="m-2">
          <button class="mybtn-blue" @click="$router.push('/home')">
            Home
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="isRogers" class="p-5 text-center">
        <p>We are unable to process your request.</p>
        <p>Please contact our support team by calling on <a href="tel:+18558860505">+1
            (855)-886-0505</a> or by emailing on <a href="mailto:services@gophonebox.com">services@gophonebox.com</a>.
        </p>
      </div>
      <div v-else>
        <!-- 1st step of setting up vacation plan -->
        <div v-show="$parent.vacationStep === 1" class="p-3">
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col sm="12" md="8">
              <div class="bg-sec p-3 mycard">
                <div class="p-2">
                  <p>
                    Do you need to put a hold on your phone plan? We offer a free
                    vacation plan for all our customers for up to 3 months. Your
                    services will be paused temporarily and your current phone plan
                    will resume once your vacation plan ends.
                  </p>
                  <div class="text-center">
                    <button class="mybtn" @click="onClickSetUpVacationPlan">
                      set up vacation plan
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="showError" class="p-3 text-danger">
                {{ errorMsg }}
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- 2nd step of setting up vacation plan  -->
        <div v-show="$parent.vacationStep === 2">
          <div class="text-center p-2 my-2">
            <b-row class="my-3">
              <b-col md="6" sm="12">
                <p class="my-2">When Do You Want to Suspend Your Service ?</p>
                <el-date-picker class="mb-2" v-model="startMonth" type="month" placeholder="Suspension Month"
                  align="center" :picker-options="pickerOptionsDateStart">
                </el-date-picker>
              </b-col>

              <b-col md="6" sm="12">
                <p class="my-2">When Do You Want to Resume Your Service ?</p>

                <el-date-picker class="mb-2" v-model="endMonth" type="month" placeholder="Reactivation Month"
                  align="center" :picker-options="pickerOptionsDateEnd">
                </el-date-picker>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col md="6" sm="12">
                <p>
                  Please note that the vacation plan can only be applied for a
                  <span class="text-danger">full billing cycle</span> with an
                  effective date on the
                  <span class="text-danger">2nd of the next month.</span> Vacation
                  plans comes with a minimum of
                  <span class="text-danger">1 month and maximum of 3 months.</span>
                  Once the vacation plan expires, the account must be in service
                  for at least <span class="text-danger">6 months</span> to be
                  eligible to apply for another vacation plan suspension.
                </p>
              </b-col>
              <b-col md="6" sm="12">
                <div class="my-3 mx-5">
                  <button class="mybtn" @click="openTheConfirmationMsgBox">
                    confirm
                  </button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BackArrow from "../../utils/BackArrow";
import MobileCloseButton from "../../utils/MobileCloseButton";
export default {
  components: {
    BackArrow,
    MobileCloseButton,
  },
  data() {
    return {
      actDate: "",
      pickerOptionsDateStart: {
        disabledDate: (time) => {
          let now = Date.now();
          let today = new Date(now);
          if (today.getDate() !== 1) {
            today.setMonth(today.getMonth() + 1);
          }

          // extract month from actDate:
          const actDateArray = this.actDate.split(" ")[0].split("/");
          const actDateMonth = Number(actDateArray[0]);
          const actDateYear = Number(actDateArray[2]);

          const nowDate = new Date();
          const currentMonth = nowDate.getMonth() + 1;
          const currentYear = nowDate.getFullYear();

          // if activation was this month, cannot request next month.
          // if today is the 26th or higher, cannot request next month.
          if (today.getDate() >= 26 || (actDateMonth === currentMonth && actDateYear === currentYear)) {
            today.setMonth(today.getMonth() + 1);
          }

          return time.getTime() < today;
        },
      },
      pickerOptionsDateEnd: {
        disabledDate: this.disabledEndDate,
      },
      isPlanChangeRequested: "",
      isTerminationRequested: "",
      isSuspensionRequested: "",
      suspensionDate: "",
      balance: "",
      startMonth: "",
      endMonth: "",
      // account has been suspended
      accountSuspended: "",
      vacationStartDate: "",
      vacationEndDate: "",
      carrier: "",
      errorMsg: "A vacation plan is not applicable with your plan at the moment. Please contact the Customer Care team for more details.",
      showError: false,
      isRogers: false,
    };
  },
  methods: {
    disabledEndDate(time) {
      let start_month = this.startMonth;
      return time.getTime() < start_month;
    },
    handleBack() {
      this.$parent.activeItem = "";
    },
    getClientInfo() {
      this.$axios
        .get("/Account/GetStatus")
        .then((response) => {
          let data = JSON.parse(response.data);
          if (data.SuspensionStatus === "Requested") {
            this.isSuspensionRequested = true;
            this.suspensionDate = data.SuspensionDate.split("T")[0];
          }
          if (data.PlanChangeStatus === "Requested") {
            this.isPlanChangeRequested = true;
          }
          if (data.TerminationStatus === "Requested") {
            this.isTerminationRequested = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get("/Account/GetProfile")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.actDate = data.ActDate;
          this.accountSuspended = data.Suspended;
          this.suspensionType = data.SuspensionReason;
          if (data.SuspensionDate !== null) {
            this.suspensionDate = data.SuspensionDate.split("T")[0];
            // sometimes suspension date is on the 1st or 3rd when the 2nd falls on the weekend -> manually change day to 2nd
            this.vacationStartDate =
              data.SuspensionDate.split("-")[0] +
              "-" +
              data.SuspensionDate.split("-")[1] +
              "-02";
          }
          if (data.VacationEndDate !== null) {
            let month = data.VacationEndDate.split("/")[1].substring(1);
            month = parseInt(month);
            month++;
            if (month > 12) {
              month = 1;
            }
            this.vacationEndDate =
              data.VacationEndDate.split("/")[0] + "-0" + month + "-02";
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.balance = data.CurrentBalance;
          this.carrier = data.Carrier;
          this.isRogers = [
            'rogers',
            'rogers-high data',
            'rogers-voice only',
            'rogers-data only'
          ].indexOf(data.Carrier.toLowerCase()) !== -1;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onClickSetUpVacationPlan() {
      if (this.carrier === ("Telus-new" || "Telus-SUB" || "Telus-SUB-Unlimited" || "Telus-SUB-Unlimited30")) {
        this.showError = true;
        return;
      }
      this.showError = false;
      this.$parent.vacationStep = 2;
    },
    verifyInput() {
      if (this.startMonth === "" || this.endMonth === "") {
        this.$notify({
          title: "Error",
          message: "Please select your start & end months !",
          type: "error",
        });
        return false;
      } else {
        return true;
      }
    },
    verifyMonth() {
      // let ms = this.endMonth - this.startMonth;
      // let days = Math.floor(ms / (24 * 60 * 60 * 1000));
      // if (days < 0) {
      //   this.$notify({
      //     title: "Error",
      //     message: "Selected Months Error !",
      //     type: "error",
      //   });
      //   return false;
      // } else if (days > 6 * 31) {
      //   this.$notify({
      //     title: "Error",
      //     message: "A vacation plan has a maximum duration of 3 months!",
      //     type: "error",
      //   });
      //   return false;
      // }
      // return true;

      // Create a deepcopy of date object
      let startDate = new Date(this.startMonth.getTime());

      const threeMonthsAfter = new Date(
        startDate.setMonth(startDate.getMonth() + 3)
      );

      // console.log("start month ", this.startMonth);
      // console.log("three after ", threeMonthsAfter);
      // console.log("end month ", this.endMonth);

      if (
        this.endMonth > threeMonthsAfter ||
        +this.startMonth === +this.endMonth
      ) {
        this.$notify({
          title: "Error",
          message: "A vacation plan has a maximum duration of 3 months!",
          type: "error",
        });
        return false;
      } else {
        return true;
      }
    },
    processSuspend() {
      if (this.verifyInput() && this.verifyMonth()) {
        let sMonth = this.startMonth.getMonth();
        let sYear = this.startMonth.getFullYear();
        let eMonth = this.endMonth.getMonth();
        let eYear = this.endMonth.getFullYear();
        // modify query para to meet api requirement
        let sdate = sYear + "-" + (sMonth + 1) + "-2";
        let edate = eYear + "-" + (eMonth + 1) + "-1";
        let url = "?sdate=" + sdate + "&edate=" + edate + "&reason=vacation";
        if (this.balance > 0) {
          this.$notify.error({
            title: "Error",
            message: "Please pay your outstanding balance first.",
            onClose: this.$router.push("/payment"),
          });
        } else if (this.isTerminationRequested) {
          this.$notify.error({
            title: "Error",
            message:
              "Sorry, you cannot suspend your plan due to a pending termination request.",
          });
        } else if (this.isPlanChangeRequested) {
          this.$notify.error({
            title: "Error",
            message:
              "Sorry, you cannot suspend your plan due to a pending plan change request.",
          });
        } else {
          this.$axios
            .post("/Account/PostSuspension" + url)
            .then((response) => {
              let data = response.data;
              // sucessful : "sent"
              if (data ===
                `"ERROR: Previous Vacation Plan Applied within the last 6 months of selected starting date!"`
              ) {
                this.$notify({
                  title: "Invalid Start Date",
                  message: data,
                  type: "error",
                });
              } else if (
                data.substring(1, 6) === "ERROR" &&
                data !==
                `"ERROR: Previous Vacation Plan Applied within the last 6 months of selected starting date!"`
              ) {
                this.$notify({
                  title: "Error",
                  message:
                    "A vacation plan has a maximum duration of 3 months!",
                  type: "error",
                });
              } else {
                this.isSuspensionRequested = true;
                this.suspensionDate = sdate;
                this.$notify({
                  title: "Success",
                  message:
                    "You have successfully requested to set up vacation plan !",
                  type: "success",
                });
                this.$router.push("/home");
              }
            })
            .catch((error) => {
              console.log(error);
              this.$notify({
                title: "Error",
                message: "Failed to suspend your plan!",
                type: "error",
              });
            });
        }
      }
    },
    openTheConfirmationMsgBox() {
      this.$confirm(
        "Are you sure you want to set your vacation plan from " +
        this.startMonth.getFullYear() +
        "-" +
        (parseInt(this.startMonth.getMonth()) + 1) +
        "-2" +
        " to " +
        this.endMonth.getFullYear() +
        "-" +
        (parseInt(this.endMonth.getMonth()) + 1) +
        "-1 ?",
        "Confirmation",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      ).then(() => {
        this.processSuspend();
      });
    },
  },
  mounted() {
    this.getClientInfo();
  }
};
</script>

<style lang="scss" scoped></style>
