<template>
  <div
    class="mx-3 my-4 px-3 py-2 mycard d-flex flex-row justify-content-between align-items-center bar bg-main"
  >
    <p class="m-0">{{ name }}</p>
    <i class="fas fa-chevron-circle-right"></i>
  </div>
</template>

<script>
export default {
  name: 'Bar',
  props: {
    "name": String
  },
};
</script>

<style lang="scss" scoped></style>
