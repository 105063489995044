<template>
  <div class="main">
    <i class="fas fa-times-circle"></i>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.main {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
}

.main:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .main {
    display: initial;
  }
}
</style>
