<template>
  <div>
    <h5 class="m-3 mb-4">Manage My Plan</h5>
    <div class="position-relative">
      <b-row class="m-3">
        <!-- left part : bars -->
        <b-col sm="12" md="4" class="p-2 bg-white mycard">
          <div @click="$router.push('/other-plans')">
            <Bar name="Change My Plan" />
          </div>
          <div @click="setActiveItem('lost')" class="position-relative">
            <Bar name="Lost or Stolen Device" />
            <div class="bridgeWrapper" v-show="activeItem === 'lost'">
              <UIbridge />
            </div>
          </div>
          <div @click="setActiveItem('vacation')" class="position-relative">
            <Bar name="Set Up a Vacation Plan" />
            <div class="bridgeWrapper" v-show="activeItem === 'vacation'">
              <UIbridge />
            </div>
          </div>
          <div @click="setActiveItem('terminate')" class="position-relative">
            <Bar name="Cancel My Services" />
            <div class="bridgeWrapper" v-show="activeItem === 'terminate'">
              <UIbridge />
            </div>
          </div>
          <div @click="setActiveItem('portout')" class="position-relative">
            <Bar name="Port-Out My Number" />
            <div class="bridgeWrapper" v-show="activeItem === 'portout'">
              <UIbridge />
            </div>
          </div>
        </b-col>
        <!-- right part : details -->
        <b-col
          md="8"
          sm="12"
          class="bg-white mycard p-4"
          v-show="activeItem !== ''"
        >
          <div class="h-100" v-show="activeItem === 'lost'">
            <Lost />
          </div>
          <div class="h-100" v-show="activeItem === 'vacation'">
            <VacationPlan />
          </div>
          <div class="h-100" v-show="activeItem === 'terminate'">
            <Terminate />
          </div>
          <div class="h-100" v-show="activeItem === 'portout'">
            <PortOut />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Bar from "../utils/Bar";
import UIbridge from "../utils/UIbridge";
import Lost from "./ManagePlanComponents/Lost";
import VacationPlan from "./ManagePlanComponents/VacationPlan";
import Terminate from "./ManagePlanComponents/Terminate";
import PortOut from "./ManagePlanComponents/PortOut";
export default {
  name: "ManagePlan",
  components: {
    Bar,
    UIbridge,
    Lost,
    VacationPlan,
    Terminate,
    PortOut,
  },
  data() {
    return {
      isMobileOrTablet: false,
      activeItem: "",
      vacationStep: 1,
      terminationStep: 1,
    };
  },
  methods: {
    setActiveItem(value) {
      this.activeItem = value;
      this.vacationStep = 1;
      this.terminationStep = 1;
      if (this.isMobileOrTablet) {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }
    },
  },
  mounted() {
    this.isMobileOrTablet = window.innerWidth <= 1024;
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .bridgeWrapper {
    display: none;
  }
}
</style>
