<template>
  <div id="planSelector">
    <b-row class="text-center mb-4">
      <b-col
        cols="6"
        class="p-3 plan d-flex align-items-center justify-content-center"
        :class="isYourPlan ? 'text-main' : 'bg-grey'"
        style="font-weight:500;font-size:18px;"
        @click="$router.push('/plan')"
      >
        My Plan
      </b-col>
      <b-col
        cols="6"
        class="p-3 plan d-flex align-items-center justify-content-center"
        :class="isYourPlan ? 'bg-grey' : 'text-main'"
        style="font-weight:500;font-size:18px;"
        @click="$router.push('/other-plans')"
      >
        Other Plans
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "PlanSelector",
  props: ["isYourPlan"],
};
</script>

<style lang="scss" scoped>
.plan {
  transition: 0.7s;
}

.plan:hover {
  cursor: pointer;
}

#planSelector {
  margin-top: 5px;
}

@media (max-width: 768px) {
  #planSelector {
    margin-top: 0px;
  }
}
</style>
