<template>
  <div
    class="bg-main mycard h-100 d-flex align-items-center justify-content-center position-relative"
  >
    <div @click="handleBack">
      <BackArrow />
    </div>
    <div @click="handleBack">
      <MobileCloseButton />
    </div>
    <div class="text-center p-4">
      <h5 class="mb-3 text-blue">Porting-out My Number</h5>
      <p class="px-4">
        At this time we cannot process a port-out request through your account
        area. <br />
        Please contact our customer support at
        <span class="text-blue">services@gophonebox.com</span> or <br />
        <span class="text-blue">+1-855-886-0505</span> to port-out your number
        to another carrier.
      </p>
    </div>
  </div>
</template>

<script>
import BackArrow from "../../utils/BackArrow";
import MobileCloseButton from "../../utils/MobileCloseButton";
export default {
  components: {
    BackArrow,
    MobileCloseButton,
  },
  methods: {
    handleBack() {
      this.$parent.activeItem = "";
      // move to bottom for mobile users -> add more code here:
      // window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
