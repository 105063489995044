import { render, staticRenderFns } from "./VacationPlan.vue?vue&type=template&id=d840d7d2&scoped=true"
import script from "./VacationPlan.vue?vue&type=script&lang=js"
export * from "./VacationPlan.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d840d7d2",
  null
  
)

export default component.exports