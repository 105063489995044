<template>
  <div class="main">
    <i class="fas fa-chevron-left"></i>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.main {
  position: absolute;
  top: 20px;
  left: 20px;
}

.main:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .main {
    display: none;
  }
}
</style>
