<template>
  <div>
    <div class="mobileNav">
      <span class="text-dark">Plan</span> <span class="mx-2">></span>
      Your Plan
    </div>
    <b-container>
      <PlanSelector :isYourPlan="true" />
      <PlanMainSection />
      <ManagePlan />
    </b-container>
  </div>
</template>

<script>
import PlanSelector from "./PlanComponents/PlanSelector";
import PlanMainSection from "./PlanComponents/PlanMainSection";
import ManagePlan from "./PlanComponents/ManagePlan";
export default {
  name: "Plan",
  components: {
    PlanSelector,
    PlanMainSection,
    ManagePlan,
  },
  mounted() {
    this.$store.commit("setCurrentNav", "Plan");
  },
};
</script>

<style lang="scss" scoped></style>
