<template>
  <div
    class="px-3 py-2 mycard d-flex flex-row justify-content-between align-items-center bar bg-main bridge"
  >
    <i class="fas fa-chevron-circle-right" style="visibility: hidden"></i>
  </div>
</template>

<script>
export default {
  name: "UIbridge",
};
</script>

<style lang="scss" scoped>
.bridge {
  display: initial;
  position: absolute;
  right: -40px;
  top: 0px;
  z-index: 22;
  width: 100px;
}
</style>
