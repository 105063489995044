<template>
  <div
    class="
      bg-main
      mycard
      h-100
      d-flex
      align-items-center
      justify-content-center
      position-relative
    "
  >
    <div @click="handleBack">
      <BackArrow />
    </div>
    <div @click="handleBack">
      <MobileCloseButton />
    </div>
    <div v-if="accountSuspended">
      <div class="text-center d-flex flex-column p-4">
        <h3 class="text-blue m-2">
          Your plan has been temporarily suspended for reported Lost/Stolen
          reasons <br />
          from {{ vacationStartDate }} .
        </h3>
        <p>
          Please contact our customer support at +1-855-886-0505 <br />
          or services@gophonebox.com to resume your service.
        </p>
        <div class="m-2">
          <button class="mybtn-blue" @click="$router.push('/home')">
            Home
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="isSuspensionRequested">
      <div class="text-center d-flex flex-column p-4">
        <h3 class="text-blue m-2">
          Your Line Has Been Requested to Be <br />
          Suspended on {{ suspensionDate }}
        </h3>
        <p>
          Please contact our customer support at +1-855-886-0505 <br />
          or services@gophonebox.com to resume your service early.
        </p>
        <div class="m-2">
          <button class="mybtn-blue" @click="$router.push('/home')">
            Home
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center p-4">
        <h5 class="mb-2 text-blue">
          Lost your phone? <br />
          Suspend your service and keep your number secure.
        </h5>
        <div class="text-center">
          <button class="mybtn-blue mt-3" @click="openTheConfirmationMsgBox">
            Suspend My Service
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackArrow from "../../utils/BackArrow";
import MobileCloseButton from "../../utils/MobileCloseButton";
export default {
  components: {
    BackArrow,
    MobileCloseButton,
  },
  data() {
    return {
      isSuspensionRequested: false,
      suspensionDate: "",
      isTerminationRequested: false,
      // account has been suspended
      accountSuspended: false,
      suspensionType: "",
      vacationStartDate: "",
      vacationEndDate: "",
    };
  },
  methods: {
    handleBack() {
      this.$parent.activeItem = "";
    },
    getPlanStatus() {
      this.$axios
        .get("/Account/GetStatus")
        .then((response) => {
          let data = JSON.parse(response.data);
          if (data.SuspensionStatus === "Requested") {
            this.isSuspensionRequested = true;
            this.suspensionDate = data.SuspensionDate.split("T")[0];
          }
          if (data.TerminationStatus === "Requested") {
            this.isTerminationRequested = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get("/Account/GetProfile")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.accountSuspended = data.Suspended;
          this.suspensionType = data.SuspensionReason;
          if (data.SuspensionDate !== null) {
            this.suspensionDate = data.SuspensionDate.split("T")[0];
            // sometimes suspension date is on the 1st or 3rd when the 2nd falls on the weekend -> manually change day to 2nd
            this.vacationStartDate =
              data.SuspensionDate.split("-")[0] +
              "-" +
              data.SuspensionDate.split("-")[1] +
              "-02";
          }
          if (data.VacationEndDate !== null) {
            let month = data.VacationEndDate.split("/")[1].substring(1);
            month = parseInt(month);
            month++;
            if (month > 12) {
              month = 1;
            }
            this.vacationEndDate =
              data.VacationEndDate.split("/")[0] + "-0" + month + "-02";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSuspendLost() {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let date = now.getDate();
      let hours = now.getHours();
      if (hours >= 18) {
        date++;
      }
      let query = year + "-" + month + "-" + date;
      let url = "?sdate=" + query + "&edate=" + query + "&reason=lost";
      if (this.isTerminationRequested) {
        this.$notify.error({
          title: "Error",
          message:
            "Sorry, you cannot suspend your plan due to a pending termination request.",
        });
      } else {
        this.$axios
          .post("/Account/PostSuspension" + url)
          .then((reponse) => {
            if (reponse.data[1] === "E") {
              this.$notify({
                title: "Error",
                message: "You have already requested to suspended your plan !",
                type: "error",
              });
            } else {
              this.$notify({
                title: "Success",
                message:
                  "You have successfully requested to suspend your plan !",
                type: "success",
              });
              this.$router.push("/home");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              title: "Error",
              message: "Failed to suspend your plan !",
              type: "error",
            });
          });
      }
    },
    openTheConfirmationMsgBox() {
      this.$confirm(
        "Are you sure you want to report lost/stolen to suspend your account？",
        "Confirmation",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      ).then(() => {
        this.handleSuspendLost();
      });
    },
  },
  mounted() {
    this.getPlanStatus();
  },
};
</script>

<style lang="scss" scoped></style>
