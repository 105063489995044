<template>
  <div
    class="bg-main p-3 mycard h-100 d-flex align-items-center justify-content-center position-relative"
  >
    <div @click="handleBack">
      <BackArrow />
    </div>
    <div @click="handleBack">
      <MobileCloseButton />
    </div>
    <div v-if="isTermination">
      <div class="text-blue text-center d-flex flex-column">
        <h4 class="m-2">Your Line Has been requested to be terminated.</h4>
        <h4 class="m-2">You will receive a termination email on</h4>
        <h4 class="m-2">
          Effective Date:
          {{ terminateDate }}
        </h4>
        <h4 class="m-2">Please check your email for more details.</h4>
        <div class="m-2">
          <button class="mybtn-blue" @click="$router.push('/home')">
            Home
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-show="$parent.terminationStep === 1" class="p-4">
        <p class="m-2">
          Are you sure you would like to terminate your service? Please note
          that you will not be able to port-out your number to another carrier
          if you terminate your service through your account area.
        </p>
        <b-row>
          <b-col sm="12" md="6">
            <div class="text-center">
              <button
                class="mybtn-blue m-3"
                @click="$parent.terminationStep = 2"
              >
                Cancel <br />
                my Service
              </button>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="text-center">
              <button
                class="mybtn-blue m-3"
                @click="$parent.activeItem = 'portout'"
              >
                Port-out My Number
              </button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-show="$parent.terminationStep === 2">
        <p class="m-2">Choose Your Cancellation Date:</p>
        <el-date-picker
          class="m-2"
          v-model="terminateDate"
          type="date"
          placeholder="Pick A Date"
          align="center"
          :picker-options="pickerOptionsTerminate"
        >
        </el-date-picker>
        <p class="m-2">Choose Your Reason for the Cancellation:</p>
        <el-select v-model="cancelationReason" class="m-2" placeholder="Leaving Canada">
          <el-option
            v-for="item in cancelationOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <p class="m-2">Comments:</p>
        <el-input
          class="m-2"
          v-model="terminateComments"
          maxlength="300"
          show-word-limit
          :rows="3"
          type="textarea"
          placeholder="Please tell us more so we can improve our offering and your future experience."
        />
        <div class="text-center m-2 pt-2">
          <button class="mybtn" @click="openTheConfirmationMsgBox">
            confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackArrow from "../../utils/BackArrow";
import MobileCloseButton from "../../utils/MobileCloseButton";
export default {
  components: {
    BackArrow,
    MobileCloseButton,
  },
  data() {
    return {
      isTermination: false,
      terminateDate: "",
      terminateComments: "",
      balance: 0,
      deviceBalance: 0,
      cancelationReason: "",
      cancelationNote: "",
      cancelationOptions: [
        {
          label: "Leaving Canada",
          value: "LC",
        },
        {
          label: "Completed port-out",
          value: "CP",
        },
        {
          label: "Extra or excessive fees",
          value: "EF",
        },
        {
          label: "Unsatisfied with the service or network",
          value: "U",
        },
        {
          label: "Others",
          value: "O",
        },
      ],
      pickerOptionsTerminate: {
        disabledDate(time) {
          var currVancouverTime = new Date().toLocaleString("en-US", {
            timeZone: "America/Vancouver",
          });
          currVancouverTime = new Date(currVancouverTime);
          var cutOffTime = new Date(
            currVancouverTime.getFullYear(),
            currVancouverTime.getMonth(),
            currVancouverTime.getDate(),
            18
          );
          if (cutOffTime - currVancouverTime > 0) {
            return time.getTime() < Date.now() - 8.64e7;
          } else {
            return time.getTime() < Date.now();
          }
        },
      },
    };
  },
  methods: {
    handleBack() {
      this.$parent.activeItem = "";
    },
    getClientInfo() {
      this.$axios
        .get("/Account/GetStatus")
        .then((response) => {
          let data = JSON.parse(response.data);
          if (data.TerminationStatus === "Requested") {
            this.isTermination = true;
            this.terminateDate = data.TerminationDate.split("T")[0];
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.balance = data.CurrentBalance;
          this.deviceBalance = data.DeviceBalance;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    processTerminate() {
      if (this.deviceBalance > 0) {
        this.$notify.error({
          title: "Error",
          message:
            "Sorry, you can not terminate your plan now because you have the device contract.",
        });
      } else if (this.terminateDate === "") {
        this.$notify({
          title: "Error",
          message: "Please select your date !",
          type: "error",
        });
      } else if (this.balance > 0) {
        this.$notify.error({
          title: "Error",
          message: "Please pay your outstanding balance first.",
          onClose: this.$router.push("/payment"),
        });
      } else if (this.cancelationReason === "") {
        this.$notify.error({
          title: "Error",
          message: "Please provide a reason for your cancelation."
        });
      } else if (this.terminateComments === "") {
        this.$notify.error({
          title: "Error",
          message: "Please provide a brief note explaining your cancelation."
        });
      } else {
        let date = this.terminateDate;
        let reason = this.cancelationReason;
        let note = this.terminateComments
        date =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();
        let url = "?terdate=" + date + "&reasonCode=" + reason + "&note=" + note;
        this.$axios
          .post("/Account/PostTermination" + url)
          .then((response) => {
            if (response.data[1] === "E") {
              this.$notify({
                title: "Error",
                message: response.data,
                type: "error",
              });
            } else {
              this.$notify({
                title: "Success",
                message:
                  "You have successfully requested to terminate your plan!",
                type: "success",
              });
              this.$router.push("/home");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              title: "Error",
              message: "Failed to terminate your plan!",
              type: "error",
            });
          });
      }
    },
    openTheConfirmationMsgBox() {
      this.$confirm(
        "Are you sure you want to cancel your services?",
        "Confirmation",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      ).then(() => {
        this.processTerminate();
      });
    },
  },
  mounted() {
    this.getClientInfo();
  },
};
</script>

<style lang="scss" scoped></style>
